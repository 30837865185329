import { Settings, DateTime } from 'luxon'
import forEach from 'lodash/forEach'
import clone from 'lodash/clone'

Settings.defaultLocale = 'en-US'
Settings.defaultZone = 'utc'

/**
 * convert number to ordinal numbers
 * https://github.com/moment/luxon/issues/118#issuecomment-781192208
 *
 * @param {Number} n
 * @returns {String}
 */
const formatOrdinals = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

DateTime.CUSTOM_FORMAT_ORDINAL = 'ordinal'
DateTime.CUSTOM_DATE_FULL = {
  ...DateTime.DATE_FULL,
  day: DateTime.CUSTOM_FORMAT_ORDINAL
}
DateTime.CUSTOM_DATETIME_MED = {
  ...DateTime.DATETIME_MED,
  day: DateTime.CUSTOM_FORMAT_ORDINAL,
  month: 'long'
}

/**
 * a workaround for n-th format
 * https://github.com/moment/luxon/issues/118
 *
 * @param {Object} formatOpts
 */
DateTime.prototype.toCustomLocaleString = function (formatOpts) {
  const ordinalFormatTypes = []
  const clonedFormatOpts = clone(formatOpts)

  forEach(clonedFormatOpts, (v, k) => {
    if (v === DateTime.CUSTOM_FORMAT_ORDINAL) {
      ordinalFormatTypes.push(k)
      clonedFormatOpts[k] = 'numeric'
    }
  })

  if (ordinalFormatTypes.length > 0) {
    return this
      .toLocaleParts(clonedFormatOpts)
      .map(({ type, value }) => {
        if (ordinalFormatTypes.indexOf(type) > -1) {
          return formatOrdinals(value)
        }

        return value
      })
      .join('')
  }

  return this.toLocaleString(clonedFormatOpts)
}

/**
 * @param {String|Number|Date|DateTime} value
 * @returns {DateTime}
 */
DateTime.fromFilterValue = (value) => {
  const options = {
    zone: 'utc'
  }

  if (typeof value === 'string') {
    return DateTime.fromISO(value, options)
  }

  if (typeof value === 'number') {
    return DateTime.fromMillis(value, options)
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value, options)
  }

  if (value instanceof DateTime) {
    return value
  }

  throw new Error('Invalid value to create DateTime')
}
