/**
 * Created by leo108 on 2019/10/20.
 */

import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import RelativeTime from '../../components/common/relative-time'

window.Vue = Vue

require('./mixins')
require('./filters')

Vue.component('Multiselect', Multiselect)
Vue.component('RelativeTime', RelativeTime)
