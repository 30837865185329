/**
 * Created by leo108 on 2019/10/20.
 */

import Swal from 'sweetalert2'
window.Swal = Swal

window.SwalTimer = Swal.mixin({
  timer: 5000,
  showConfirmButton: false
})

window.SwalConfirm = Swal.mixin({
  timer: null,
  showCancelButton: true,
  showConfirmButton: true,
  showLoaderOnConfirm: true
})

window.SwalAlert = Swal.mixin({
  timer: null,
  showCancelButton: false,
  showConfirmButton: true,
  confirmButtonText: 'OK'
})
