/**
 * Created by leo108 on 16/9/2.
 */

import * as Sentry from '@sentry/browser'
import URI from 'urijs'

export default (routes) => {
  return (route, param = {}, absolute = false) => {
    if (typeof routes[route] === 'undefined') {
      Sentry.withScope(scope => {
        scope.setExtra('param', param)
          .setFingerprint(['undefined-router', route])
          .setLevel('fatal')
        Sentry.captureMessage(`Undefined route ${route}`)
      })
      console.log(`Undefined route ${route}`)

      return null
    }

    let routeUrl = routes[route]
    const append = []

    for (const x in param) {
      const search = '{' + x + '}'

      if (routeUrl.indexOf(search) >= 0) {
        routeUrl = routeUrl.replace('{' + x + '}', param[x])
      } else {
        if (typeof param[x] !== 'object') {
          append.push(x + '=' + encodeURIComponent(param[x]))
        } else if (param[x] instanceof Array) {
          param[x].forEach(item => {
            append.push(x + '[]=' + encodeURIComponent(item))
          })
        } else {
          for (const key in param[x]) {
            append.push(x + '[' + key + ']=' + encodeURIComponent(param[x][key]))
          }
        }
      }
    }

    if (/{\w+\?}$/.test(routeUrl)) {
      routeUrl = routeUrl.replace(/{\w+\?}$/, '')
    }

    let url = '/' + routeUrl

    if (append.length > 0) {
      if (url.indexOf('?') >= 0) {
        url += '&'
      } else {
        url += '?'
      }

      url += append.join('&')
    }

    if (absolute) {
      const current = new URI(location.href)
      const uri = new URI(url)
      uri.hostname(current.hostname())
      uri.scheme(current.scheme())

      url = uri.toString()
    }

    return url
  }
}
