/**
 * Created by leo108 on 2019/10/20.
 */
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

function configureSentryUser () {
  if (Spark.state.user) {
    Sentry.getCurrentScope().setUser({
      id: Spark.state.user.id,
      email: Spark.state.user.email
    })
  }
}

if (process.env.APP_ENV !== 'local') {
  Sentry.init({
    Vue,
    dsn: 'https://8dff49c8a529496882b8db7ffd45d27e@o4505855671599104.ingest.sentry.io/4505881156780032',
    autoSessionTracking: false,
    integrations: [
      Sentry.globalHandlersIntegration({ onunhandledrejection: false })
    ],
    release: process.env.CI_PIPELINE_IID ? process.env.CI_PIPELINE_IID : null,
    environment: process.env.APP_ENV,
    allowUrls: [
      /dubb/
    ],
    beforeSend (event) {
      event.logger = 'javascript'

      return event
    }
  })

  configureSentryUser()

  window.Bus.$on('login-user', () => {
    configureSentryUser()
  })
}
