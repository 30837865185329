/**
 * Created by leo108 on 2019/11/16.
 */
import axios from 'axios'

window.Bus.$on('refresh-state', () => {
  axios.get(Spark.router('api.frontend_state'))
    .then(resp => {
      Spark.state.user = resp.data.user
      Spark.state.currentTeam = resp.data.currentTeam

      window.Bus.$emit('state-refreshed')
    })
})
