module.exports = {
    "cashier.payment": "stripe\/payment\/{id}",
    "cashier.webhook": "stripe\/webhook",
    "horizon.stats.index": "horizon\/api\/stats",
    "horizon.workload.index": "horizon\/api\/workload",
    "horizon.masters.index": "horizon\/api\/masters",
    "horizon.monitoring.index": "horizon\/api\/monitoring",
    "horizon.monitoring.store": "horizon\/api\/monitoring",
    "horizon.monitoring-tag.paginate": "horizon\/api\/monitoring\/{tag}",
    "horizon.monitoring-tag.destroy": "horizon\/api\/monitoring\/{tag}",
    "horizon.jobs-metrics.index": "horizon\/api\/metrics\/jobs",
    "horizon.jobs-metrics.show": "horizon\/api\/metrics\/jobs\/{id}",
    "horizon.queues-metrics.index": "horizon\/api\/metrics\/queues",
    "horizon.queues-metrics.show": "horizon\/api\/metrics\/queues\/{id}",
    "horizon.jobs-batches.index": "horizon\/api\/batches",
    "horizon.jobs-batches.show": "horizon\/api\/batches\/{id}",
    "horizon.jobs-batches.retry": "horizon\/api\/batches\/retry\/{id}",
    "horizon.pending-jobs.index": "horizon\/api\/jobs\/pending",
    "horizon.completed-jobs.index": "horizon\/api\/jobs\/completed",
    "horizon.silenced-jobs.index": "horizon\/api\/jobs\/silenced",
    "horizon.failed-jobs.index": "horizon\/api\/jobs\/failed",
    "horizon.failed-jobs.show": "horizon\/api\/jobs\/failed\/{id}",
    "horizon.retry-jobs.show": "horizon\/api\/jobs\/retry\/{id}",
    "horizon.jobs.show": "horizon\/api\/jobs\/{id}",
    "horizon.index": "horizon\/{view?}",
    "passport.token": "oauth\/token",
    "passport.authorizations.authorize": "oauth\/authorize",
    "passport.token.refresh": "oauth\/token\/refresh",
    "passport.authorizations.approve": "oauth\/authorize",
    "passport.authorizations.deny": "oauth\/authorize",
    "passport.tokens.index": "oauth\/tokens",
    "passport.tokens.destroy": "oauth\/tokens\/{token_id}",
    "passport.clients.index": "oauth\/clients",
    "passport.clients.store": "oauth\/clients",
    "passport.clients.update": "oauth\/clients\/{client_id}",
    "passport.clients.destroy": "oauth\/clients\/{client_id}",
    "passport.scopes.index": "oauth\/scopes",
    "passport.personal.tokens.index": "oauth\/personal-access-tokens",
    "passport.personal.tokens.store": "oauth\/personal-access-tokens",
    "passport.personal.tokens.destroy": "oauth\/personal-access-tokens\/{token_id}",
    "login": "login",
    "logout": "logout",
    "register": "register",
    "password.request": "password\/reset",
    "password.email": "password\/email",
    "password.reset": "password\/reset\/{token}",
    "password.update": "password\/reset",
    "verification.notice": "email\/verify",
    "verification.verify": "email\/verify\/{id}\/{hash}",
    "verification.resend": "email\/resend",
    "web.welcome": "",
    "web.quick-register": "quick-sign-up",
    "web.login.2fa": "login\/2fa",
    "web.login.2fa.verify": "login\/2fa\/verify",
    "web.oauth.lk.callback": "connect\/lk\/callback",
    "web.oauth.lk.redirect": "connect\/lk",
    "web.oauth.fb.callback": "connect\/fb\/callback",
    "web.oauth.fb.redirect": "connect\/fb",
    "web.oauth.tw.callback": "connect\/tw\/callback",
    "web.oauth.tw.redirect": "connect\/tw",
    "web.oauth.google.redirect": "connect\/google",
    "web.oauth.google.callback": "connect\/google\/callback",
    "web.auth.launch.mobile-app": "auth\/launch\/mobile-app",
    "web.oauth.google.onetap": "oauth\/google\/onetap",
    "web.announcement.index": "announcement",
    "web.announcement.show": "announcement\/{announcement}",
    "web.unsubscribe": "unsubscribe",
    "web.unsubscribe.submit": "unsubscribe",
    "web.pricing": "pricing",
    "web.addons": "addons",
    "web.chrome.unsubscribe": "chrome-extension-unsubscribe",
    "web.chrome.welcome": "chrome-extension-welcome",
    "web.chrome.intro": "subscription-chrome-extension-intro",
    "web.chrome.uploading": "chrome-extension-uploading",
    "web.showcase.show": "s\/{slug}",
    "web.showcase.embed": "s\/{slug}\/embed",
    "web.email-template.share": "e\/{email_template}\/share",
    "web.video.show": "v\/{canonical}",
    "web.video.portfolio": "v\/{canonical}\/portfolio",
    "web.video.check": "v\/{canonical}\/check",
    "web.video.appshare": "v\/{canonical}\/appshare",
    "web.video.embed": "v\/{canonical}\/embed",
    "web.video.reply.show": "v\/{canonical}\/reply\/{reply}",
    "web.embed.form": "embed\/form\/{form}",
    "web.embed.create-video": "embed\/video\/create",
    "web.zoom-app.index": "zoom-app",
    "web.affiliate.sso": "affiliate\/sso",
    "web.dashboard": "dashboard\/{any?}",
    "web.subscription": "subscription",
    "web.subdomain": "subdomain",
    "web.oauth.youtube.redirect": "connect\/youtube",
    "web.oauth.youtube.callback": "connect\/youtube\/callback",
    "web.video.create": "video\/create",
    "web.video.edit": "v\/{canonical}\/edit",
    "web.show_verify_phone": "verify-phone",
    "web.show_invite_team_members": "invite-team-members",
    "web.team.user.impersonate": "team\/users\/impersonate\/{user}",
    "web.team.user.stop_impersonate": "team\/users\/stop-impersonating",
    "web.settings": "settings\/{any?}",
    "web.extend_trial": "extend_trial",
    "web.redeem_partner_code": "redeem-partner-code",
    "web.landing-page.builder": "landing-page\/{landing_page}\/builder",
    "web.landing-page.preview": "landing-page\/{landing_page}\/preview",
    "web.campaign.info": "campaign\/{campaign}\/info",
    "web.campaign.preview": "campaign\/{campaign}\/preview",
    "web.avatar-video.show": "avatar-video\/{avatar_video}",
    "web.handle_agency_request": "agency_request\/{delegation}",
    "web.switch_account": "switch_account",
    "web.switch_to": "switch_account",
    "web.broadcast-provider.gmail.redirect": "broadcast-provider\/gmail\/redirect",
    "web.broadcast-provider.gmail.callback": "broadcast-provider\/gmail\/callback",
    "web.broadcast-provider.outlook.redirect": "broadcast-provider\/outlook\/redirect",
    "web.broadcast-provider.outlook.callback": "broadcast-provider\/outlook\/callback",
    "web.broadcast-provider.sales-message.redirect": "broadcast-provider\/sales-message\/redirect",
    "web.broadcast-provider.sales-message.callback": "broadcast-provider\/sales-message\/callback",
    "web.integration.aweber.redirect": "integration\/aweber\/redirect",
    "web.integration.aweber.callback": "integration\/aweber\/callback",
    "web.integration.facebook-page.redirect": "integration\/facebook-page\/redirect",
    "web.integration.facebook-page.callback": "integration\/facebook-page\/callback",
    "web.integration.high-level.redirect": "integration\/high-level\/redirect",
    "web.integration.high-level.callback": "integration\/xgcfbixe\/callback",
    "web.integration.google-contact.redirect": "integration\/google-contact\/redirect",
    "web.integration.google-contact.callback": "integration\/google-contact\/callback",
    "web.integration.hubspot.redirect": "integration\/hubspot\/redirect",
    "web.integration.hubspot.callback": "integration\/hubspot\/callback",
    "web.integration.infusionsoft.redirect": "integration\/infusionsoft\/redirect",
    "web.integration.infusionsoft.callback": "integration\/infusionsoft\/callback",
    "web.integration.lofty.redirect": "integration\/lofty\/redirect",
    "web.integration.lofty.callback": "integration\/lofty\/callback",
    "web.integration.outlook-contact.redirect": "integration\/outlook-contact\/redirect",
    "web.integration.outlook-contact.callback": "integration\/outlook-contact\/callback",
    "web.integration.pipedrive.redirect": "integration\/pipedrive\/redirect",
    "web.integration.pipedrive.callback": "integration\/pipedrive\/callback",
    "web.integration.salesforce.redirect": "integration\/salesforce\/redirect",
    "web.integration.salesforce.callback": "integration\/salesforce\/callback",
    "web.integration.salesloft.redirect": "integration\/salesloft\/redirect",
    "web.integration.salesloft.callback": "integration\/salesloft\/callback",
    "web.integration.slack.redirect": "integration\/slack\/redirect",
    "web.integration.slack.callback": "integration\/slack\/callback",
    "web.qrcode": "qrcode",
    "web.auth.launch.chrome-extension": "auth\/launch\/chrome-extension",
    "web.auth.launch.outlook-addin": "auth\/launch\/outlook-addin",
    "web.auth.launch.desktop-app": "auth\/launch\/desktop-app",
    "web.zoom-app.authorization": "zoom-app\/authorization",
    "web.zoom-app.callback": "zoom-app\/callback",
    "web.email-template.create": "email-template\/create",
    "web.email-template.preview": "email-template\/{email_template}\/preview",
    "web.email-template.edit": "email-template\/{email_template}\/edit",
    "web.video_template.edit": "video_template\/{video_template}\/edit",
    "web.video_template.preview": "video_template\/{video_template}\/preview",
    "web.messaging": "messaging\/{any?}",
    "web.kiosk.user.stop-impersonating": "spark\/kiosk\/user\/stop-impersonating",
    "web.login_by_key": "login_by_key",
    "web.landing-page.show": "p\/{slug}",
    "web.amplify": "amplify",
    "web.guest_video.create": "video-generator\/create",
    "web.guest_video.store": "video-generator",
    "web.guest_video.show": "v\/video\/{guestVideo}",
    "web.guest_video.edit": "video-generator\/{guestVideo}\/edit",
    "web.guest_video.update": "video-generator\/{guestVideo}",
    "web.guest_video.destroy": "video-generator\/{guestVideo}",
    "web.tools.convertor.create": "tools\/convertor",
    "web.tools.convertor.store": "tools\/convertor",
    "web.tools.convertor.show": "tools\/convertor\/{job}",
    "web.verify_phone_code": "verify-phone-code",
    "web.pages.no-role-permission": "no-role-permission",
    "web.terms": "terms",
    "web.privacy": "privacy",
    "web.refund_policy": "refund-policy",
    "web.cookie_policy": "cookie-policy",
    "web.video.import": "video\/import",
    "web.contact": "contact",
    "web.pages.marketplace": "marketplace",
    "web.pages.partners": "partners",
    "web.pages.coaches": "coaches",
    "web.pages.resources": "resources",
    "web.fallback": "{fallbackPlaceholder}",
    "web.oembed": "oembed",
    "web.": ".well-known\/acme-challenge\/{token}",
    "web.track.pixel": "track\/pixel.png",
    "web.campaign_forwarder.webpage": "c\/{campaign}",
    "web.campaign_forwarder.link": "c\/{campaign}\/l",
    "web.affiliate.redirect": "r\/{username}",
    "web.showcase.thumbnail": "s\/{slug}\/thumbnail",
    "web.team_asset.show": "ta\/{asset}\/{filename}",
    "web.user-social-profile.show": "u\/{username}",
    "web.user-social-profile.permanent-url": "uh\/{hashid}",
    "web.video.no-play-button-url": "v\/{canonical}\/1",
    "web.video.gif_url": "v\/{canonical}\/preview.gif",
    "web.video.gif_with_play_url": "v\/{canonical}\/preview_with_play.gif",
    "web.video.preview_url": "v\/{canonical}\/preview.png",
    "web.video.preview_with_play_url": "v\/{canonical}\/preview_with_play.png",
    "web.video.permanent-url": "vh\/{hashid}",
    "web.sso.login": "sso\/{slug}",
    "web.sso.acs": "sso\/{slug}\/acs",
    "web.sso.capture-request": "sso\/{slug}\/acs",
    "web.sso.metadata": "sso\/{slug}\/metadata",
    "web.kiosk.api.kiosk_user.index": "spark\/kiosk\/api\/kiosk_user",
    "web.kiosk.api.kiosk_user.update": "spark\/kiosk\/api\/kiosk_user\/{kiosk_user}",
    "web.kiosk.api.announcement.index": "spark\/kiosk\/api\/announcement",
    "web.kiosk.api.announcement.store": "spark\/kiosk\/api\/announcement",
    "web.kiosk.api.announcement.show": "spark\/kiosk\/api\/announcement\/{announcement}",
    "web.kiosk.api.announcement.update": "spark\/kiosk\/api\/announcement\/{announcement}",
    "web.kiosk.api.announcement.destroy": "spark\/kiosk\/api\/announcement\/{announcement}",
    "web.kiosk.api.announcement.broadcast": "spark\/kiosk\/api\/announcement\/{announcement}\/broadcast",
    "web.kiosk.api.lead.export": "spark\/kiosk\/api\/lead\/export",
    "web.kiosk.api.lead.upload": "spark\/kiosk\/api\/lead\/upload",
    "web.kiosk.api.lead.unsubscribe": "spark\/kiosk\/api\/lead\/{lead}\/unsubscribe",
    "web.kiosk.api.lead.index": "spark\/kiosk\/api\/lead",
    "web.kiosk.api.lead.store": "spark\/kiosk\/api\/lead",
    "web.kiosk.api.lead.update": "spark\/kiosk\/api\/lead\/{lead}",
    "web.kiosk.api.lead.destroy": "spark\/kiosk\/api\/lead\/{lead}",
    "web.kiosk.api.page.index": "spark\/kiosk\/api\/page",
    "web.kiosk.api.page.store": "spark\/kiosk\/api\/page",
    "web.kiosk.api.page.show": "spark\/kiosk\/api\/page\/{page}",
    "web.kiosk.api.page.update": "spark\/kiosk\/api\/page\/{page}",
    "web.kiosk.api.page.destroy": "spark\/kiosk\/api\/page\/{page}",
    "web.kiosk.api.page_element.index": "spark\/kiosk\/api\/page_element",
    "web.kiosk.api.page_element.store": "spark\/kiosk\/api\/page_element",
    "web.kiosk.api.page_element.show": "spark\/kiosk\/api\/page_element\/{page_element}",
    "web.kiosk.api.page_element.update": "spark\/kiosk\/api\/page_element\/{page_element}",
    "web.kiosk.api.page_element.destroy": "spark\/kiosk\/api\/page_element\/{page_element}",
    "web.kiosk.api.redirection.index": "spark\/kiosk\/api\/redirection",
    "web.kiosk.api.redirection.store": "spark\/kiosk\/api\/redirection",
    "web.kiosk.api.redirection.update": "spark\/kiosk\/api\/redirection\/{redirection}",
    "web.kiosk.api.redirection.destroy": "spark\/kiosk\/api\/redirection\/{redirection}",
    "web.kiosk.api.markdown.index": "spark\/kiosk\/api\/markdown",
    "web.kiosk.api.markdown.update": "spark\/kiosk\/api\/markdown",
    "web.kiosk.api.markdown.preview": "spark\/kiosk\/api\/markdown\/preview",
    "web.kiosk.api.setting.index": "spark\/kiosk\/api\/setting",
    "web.kiosk.api.setting.update": "spark\/kiosk\/api\/setting",
    "web.kiosk.api.subscriber.index": "spark\/kiosk\/api\/subscriber",
    "web.kiosk.api.subscriber.export": "spark\/kiosk\/api\/subscriber\/export",
    "web.kiosk.api.cancellation.index": "spark\/kiosk\/api\/cancellation",
    "web.kiosk.api.cancellation.export": "spark\/kiosk\/api\/cancellation\/export",
    "web.kiosk.api.team.update_trial": "spark\/kiosk\/api\/team\/{team}\/trial",
    "web.kiosk.api.team.extra.patch": "spark\/kiosk\/api\/team\/{team}\/extra",
    "web.kiosk.api.team.subscriptions.set_cancel_reason": "spark\/kiosk\/api\/team\/{team}\/subscriptions\/{subscription}\/canceled_subscription.set_cancel_reason",
    "web.kiosk.api.team.patch": "spark\/kiosk\/api\/team\/{team}",
    "web.kiosk.api.team.connect-stripe": "spark\/kiosk\/api\/team\/{team}\/stripe-id",
    "web.kiosk.api.team.create-stripe-customer": "spark\/kiosk\/api\/team\/{team}\/create-stripe-customer",
    "web.kiosk.api.team.import-users": "spark\/kiosk\/api\/team\/{team}\/import-users",
    "web.kiosk.api.team.index": "spark\/kiosk\/api\/team",
    "web.kiosk.api.team.show": "spark\/kiosk\/api\/team\/{team}",
    "web.kiosk.api.team.destroy": "spark\/kiosk\/api\/team\/{team}",
    "web.kiosk.api.team-sso-configuration.index": "spark\/kiosk\/api\/team_sso_configuration",
    "web.kiosk.api.team-sso-configuration.store": "spark\/kiosk\/api\/team_sso_configuration",
    "web.kiosk.api.team-sso-configuration.update": "spark\/kiosk\/api\/team_sso_configuration\/{team_sso_configuration}",
    "web.kiosk.api.user.export": "spark\/kiosk\/api\/user\/export",
    "web.kiosk.api.user.block": "spark\/kiosk\/api\/user\/{user}\/block",
    "web.kiosk.api.user.unblock": "spark\/kiosk\/api\/user\/{user}\/unblock",
    "web.kiosk.api.user.disconnect-social": "spark\/kiosk\/api\/user\/{user}\/disconnect-social",
    "web.kiosk.api.user.reset-password": "spark\/kiosk\/api\/user\/{user}\/reset-password",
    "web.kiosk.api.user.patch": "spark\/kiosk\/api\/user\/{user}",
    "web.kiosk.api.user.index": "spark\/kiosk\/api\/user",
    "web.kiosk.api.user.show": "spark\/kiosk\/api\/user\/{user}",
    "web.kiosk.api.user.destroy": "spark\/kiosk\/api\/user\/{user}",
    "web.kiosk.api.metrics.basic": "spark\/kiosk\/api\/metrics\/basic",
    "web.kiosk.api.metrics.compare": "spark\/kiosk\/api\/metrics\/compare",
    "web.kiosk.api.metrics.metabase": "spark\/kiosk\/api\/metrics\/metabase",
    "web.kiosk.api.activity-report.video": "spark\/kiosk\/api\/activity-report\/video",
    "web.kiosk.api.activity-report.campaign": "spark\/kiosk\/api\/activity-report\/campaign",
    "web.kiosk.api.partner_code.show": "spark\/kiosk\/api\/partner-code\/{code}",
    "web.kiosk.api.partner_code.unassociate": "spark\/kiosk\/api\/partner-code\/{code}\/unassociate",
    "web.kiosk.api.upload": "spark\/kiosk\/api\/upload",
    "web.kiosk.api.historical-value.index": "spark\/kiosk\/api\/historical-value",
    "web.kiosk.api.object.index": "spark\/kiosk\/api\/object\/{type}",
    "web.kiosk.api.object.store": "spark\/kiosk\/api\/object\/{type}",
    "web.kiosk.api.object.show": "spark\/kiosk\/api\/object\/{type}\/{object}",
    "web.kiosk.api.object.update": "spark\/kiosk\/api\/object\/{type}\/{object}",
    "web.kiosk.api.object.move": "spark\/kiosk\/api\/object\/{type}\/{object}\/move",
    "web.kiosk.api.object.destroy": "spark\/kiosk\/api\/object\/{type}\/{object}",
    "web.kiosk.api.email-template.import-unlayer": "spark\/kiosk\/api\/email-template\/import-unlayer",
    "web.kiosk.api.landing-page.import-unlayer": "spark\/kiosk\/api\/landing-page\/import-unlayer",
    "web.kiosk.api.saml.parse-idp-metadata": "spark\/kiosk\/api\/saml\/parse-idp-metadata",
    "web.kiosk.user.impersonate": "spark\/kiosk\/user\/impersonate\/{user}",
    "web.kiosk": "spark\/kiosk\/{any?}",
    "api.register": "api\/register",
    "api.oauth.profile.get": "api\/oauth_profile",
    "api.password.reset": "api\/password\/reset",
    "api.auth.login_by_biometric": "api\/auth\/login_by_biometric",
    "api.auth.login_by_apple": "api\/auth\/login_by_apple",
    "api.video.track": "api\/video\/{video}\/track",
    "api.video.show": "api\/video\/{video}",
    "api.video.show-by-canonical": "api\/video-by-canonical\/{canonical}",
    "api.video.resolution.update": "api\/video\/{video}\/resolution",
    "api.video.subtitle.download": "api\/video\/{video}\/subtitle",
    "api.showcase.show": "api\/showcase\/{showcase}",
    "api.check-username": "api\/check-username",
    "api.generate-username": "api\/generate-username",
    "api.check-email": "api\/check-email",
    "api.check-team-slug": "api\/check-team-slug",
    "api.configuration.show": "api\/configuration\/{key}",
    "api.dubb-object.index": "api\/dubb-object\/{type}",
    "api.announcement.index": "api\/announcement",
    "api.my.web_token": "api\/web_token",
    "api.affiliate-partner.store": "api\/affiliate-partner",
    "api.profile.update": "api\/profile",
    "api.profile.photo.update": "api\/profile\/photo",
    "api.email-signature.show": "api\/email-signature",
    "api.email-signature.update": "api\/email-signature",
    "api.oauth.user": "api\/oauth\/user",
    "api.frontend_state": "api\/frontend_state",
    "api.me.patch": "api\/me",
    "api.me.destroy": "api\/me",
    "api.me.send_phone_verify_sms": "api\/me\/phone",
    "api.me.agency.set": "api\/me\/agency",
    "api.me.web_login_url": "api\/me\/web_login_url",
    "api.user_biometric.index": "api\/user_biometric",
    "api.user_biometric.store": "api\/user_biometric",
    "api.user_biometric.destroy": "api\/user_biometric\/{user_biometric}",
    "api.invitation.bulk_store": "api\/invitation\/bulk",
    "api.user_setting.patch": "api\/user_setting",
    "api.upload.presign": "api\/upload\/presign",
    "api.upload.preview": "api\/upload\/preview",
    "api.upload.video-cover": "api\/upload\/video-cover",
    "api.upload.video-preview": "api\/upload\/video-preview",
    "api.upload.video-template-meta": "api\/upload\/video-template-meta",
    "api.upload.showcase-image": "api\/upload\/showcase-image",
    "api.upload.landing-page-favicon": "api\/upload\/landing-page-favicon",
    "api.dashboard.team_stat": "api\/dashboard\/team-stat",
    "api.dashboard.team_stat.export": "api\/dashboard\/team-stat\/export",
    "api.dashboard.stat": "api\/dashboard\/stat",
    "api.dashboard.affiliate-partner-stat": "api\/dashboard\/affiliate-partner-stat",
    "api.dashboard.video_view": "api\/dashboard\/video_view",
    "api.dashboard.sidebar": "api\/dashboard\/sidebar",
    "api.notification.index": "api\/notification",
    "api.notification.read": "api\/notification\/read",
    "api.announcement.read": "api\/announcement\/{announcement}\/read",
    "api.video.index": "api\/video",
    "api.video.store": "api\/video",
    "api.video.store_from_placeholder": "api\/video\/placeholder",
    "api.video.clear_reporting": "api\/video\/{video}\/clear-reporting",
    "api.video.update": "api\/video\/{video}",
    "api.video.analysis": "api\/video\/{video}\/analysis",
    "api.video.analyze": "api\/video\/{video}\/analyze",
    "api.video.duplicate": "api\/video\/{video}\/duplicate",
    "api.video.email_html": "api\/video\/{video}\/email-html",
    "api.video.patch": "api\/video\/{video}",
    "api.video.trim": "api\/video\/{video}\/trim",
    "api.video.upload_to_youtube": "api\/video\/{video}\/upload-to-youtube",
    "api.video.transcribe": "api\/video\/{video}\/transcribe",
    "api.video.canonical.update": "api\/video\/{video}\/canonical",
    "api.video.event.store": "api\/video\/{video}\/event",
    "api.video.preset.apply": "api\/video\/{video}\/preset\/{preset}",
    "api.video.preset.clear": "api\/video\/{video}\/clear-preset",
    "api.video.destroy": "api\/video\/{video}",
    "api.video.subtitle.upload": "api\/video\/{video}\/subtitle",
    "api.video.subtitle.destroy": "api\/video\/{video}\/subtitle",
    "api.video.drop-off-chart": "api\/video\/{video}\/drop-off-chart",
    "api.video.bulk-action.destroy": "api\/video\/bulk-action\/destroy",
    "api.video.bulk-action.set_tags": "api\/video\/bulk-action\/tags",
    "api.video.bulk-action.remove-tags": "api\/video\/bulk-action\/remove-tags",
    "api.video.bulk-action.shared": "api\/video\/bulk-action\/shared",
    "api.conversation.index": "api\/conversation",
    "api.conversation.show": "api\/conversation\/{conversation}",
    "api.conversation.find": "api\/conversation\/find\/{friend}",
    "api.conversation.messages": "api\/conversation\/{conversation}\/messages",
    "api.conversation.send": "api\/conversation\/send\/{friend}",
    "api.conversation.block": "api\/conversation\/{conversation}\/block",
    "api.conversation.hide": "api\/conversation\/{conversation}\/hide",
    "api.conversation.toggle-ai": "api\/conversation\/{conversation}\/toggle-ai",
    "api.conversation.read": "api\/conversation\/{conversation}\/read",
    "api.team.store": "api\/team",
    "api.sales-assistant.index": "api\/sales-assistant",
    "api.sales-assistant.store": "api\/sales-assistant",
    "api.sales-assistant.show": "api\/sales-assistant\/{sales_assistant}",
    "api.sales-assistant.update": "api\/sales-assistant\/{sales_assistant}",
    "api.sales-assistant.destroy": "api\/sales-assistant\/{sales_assistant}",
    "api.sales-assistant.init": "api\/sales-assistant\/init",
    "api.sales-assistant.clone": "api\/sales-assistant\/{sales_assistant}\/clone",
    "api.ai-completion.store": "api\/ai-completion",
    "api.ai-profile.index": "api\/ai-profile",
    "api.ai-profile.store": "api\/ai-profile",
    "api.ai-profile.show": "api\/ai-profile\/{ai_profile}",
    "api.ai-profile.update": "api\/ai-profile\/{ai_profile}",
    "api.ai-profile.destroy": "api\/ai-profile\/{ai_profile}",
    "api.avatar-video.store": "api\/avatar-video",
    "api.avatar-video.show": "api\/avatar-video\/{avatar_video}",
    "api.avatar-video.destroy": "api\/avatar-video\/{avatar_video}",
    "api.avatar-video.patch": "api\/avatar-video\/{avatar_video}",
    "api.avatar-video.upload": "api\/avatar-video\/{avatar_video}\/upload",
    "api.contact.index": "api\/contact",
    "api.contact.store": "api\/contact",
    "api.contact.show": "api\/contact\/{contact}",
    "api.contact.update": "api\/contact\/{contact}",
    "api.contact.patch": "api\/contact\/{contact}",
    "api.contact.destroy": "api\/contact\/{contact}",
    "api.contact.campaigns": "api\/contact\/{contact}\/campaigns",
    "api.contact.workflows": "api\/contact\/{contact}\/workflows",
    "api.contact.tasks": "api\/contact\/{contact}\/tasks",
    "api.contact.deals": "api\/contact\/{contact}\/deals",
    "api.contact.toggle_subscribed": "api\/contact\/{contact}\/subscribed",
    "api.contact.bulk-action.destroy": "api\/contact\/bulk-action\/destroy",
    "api.contact.bulk-action.add-tags": "api\/contact\/bulk-action\/add-tags",
    "api.contact.bulk-action.remove-tags": "api\/contact\/bulk-action\/remove-tags",
    "api.contact.bulk-action.set-owner": "api\/contact\/bulk-action\/set-owner",
    "api.contact.sync": "api\/contact\/sync",
    "api.contact.upload": "api\/contact\/upload",
    "api.contact.get_csv_header": "api\/contact\/csv-header",
    "api.contact.clean-phone-numbers": "api\/contact\/clean-phone-numbers",
    "api.contact.note.index": "api\/contact\/{contact}\/note",
    "api.contact.note.store": "api\/contact\/{contact}\/note",
    "api.contact.note.update": "api\/contact\/{contact}\/note\/{contact_note}",
    "api.contact.note.destroy": "api\/contact\/{contact}\/note\/{contact_note}",
    "api.company.index": "api\/company",
    "api.company.store": "api\/company",
    "api.company.update": "api\/company\/{company}",
    "api.company.destroy": "api\/company\/{company}",
    "api.company.bulk.destroy": "api\/company\/bulk\/destroy",
    "api.company.bulk-action.destroy": "api\/company\/bulk-action\/destroy",
    "api.form.index": "api\/form",
    "api.form.store": "api\/form",
    "api.form.show": "api\/form\/{form}",
    "api.form.update": "api\/form\/{form}",
    "api.form.destroy": "api\/form\/{form}",
    "api.form.submission.index": "api\/form\/{form}\/submission",
    "api.form.submission.export": "api\/form\/{form}\/submission\/export",
    "api.script.index": "api\/script",
    "api.script.store": "api\/script",
    "api.script.show": "api\/script\/{script}",
    "api.script.update": "api\/script\/{script}",
    "api.script.destroy": "api\/script\/{script}",
    "api.script.clone": "api\/script\/{script}\/clone",
    "api.script.bulk-action.set-owner": "api\/script\/bulk-action\/set-owner",
    "api.script.bulk-action.set-shared": "api\/script\/bulk-action\/set-shared",
    "api.script.bulk-action.destroy": "api\/script\/bulk-action\/destroy",
    "api.showcase.index": "api\/showcase",
    "api.showcase.store": "api\/showcase",
    "api.showcase.destroy": "api\/showcase\/{showcase}",
    "api.showcase.update": "api\/showcase\/{showcase}",
    "api.showcase.patch": "api\/showcase\/{showcase}",
    "api.task.index": "api\/task",
    "api.task.store": "api\/task",
    "api.task.show": "api\/task\/{task}",
    "api.task.update": "api\/task\/{task}",
    "api.task.destroy": "api\/task\/{task}",
    "api.task.update_status": "api\/task\/{task}\/status",
    "api.team.announcement.public-index": "api\/team\/announcement\/public",
    "api.team.announcement.read": "api\/team\/announcement\/{announcement}\/read",
    "api.team.announcement.index": "api\/team\/announcement",
    "api.team.announcement.store": "api\/team\/announcement",
    "api.team.announcement.show": "api\/team\/announcement\/{announcement}",
    "api.team.announcement.update": "api\/team\/announcement\/{announcement}",
    "api.team.announcement.destroy": "api\/team\/announcement\/{announcement}",
    "api.team.transfer": "api\/team\/transfer",
    "api.team.patch": "api\/team",
    "api.team.extra-billing-information.update": "api\/team\/extra-billing-information",
    "api.team.destroy": "api\/team",
    "api.team.usages": "api\/team\/usages",
    "api.team.member.index": "api\/team\/member",
    "api.team.member.patch": "api\/team\/member\/{member}",
    "api.team.member.destroy": "api\/team\/member\/{member}",
    "api.team.member.reset-password": "api\/team\/member\/{member}\/reset-password",
    "api.team.member.reset-password.link": "api\/team\/member\/{member}\/reset-password\/link",
    "api.team.member.reset-password.email": "api\/team\/member\/{member}\/reset-password\/email",
    "api.team.member.bulk-action.assign-role": "api\/team\/member\/bulk-action\/assign-role",
    "api.team.asset.index": "api\/team\/asset",
    "api.team.asset.store": "api\/team\/asset",
    "api.team.asset.patch": "api\/team\/asset\/{asset}",
    "api.team.asset.destroy": "api\/team\/asset\/{asset}",
    "api.team.asset.create-images": "api\/team\/asset\/create-images",
    "api.team.domain.update": "api\/team\/domain\/{type}",
    "api.team.domain.destroy": "api\/team\/domain\/{type}",
    "api.team.role.index": "api\/team\/role",
    "api.team.role.store": "api\/team\/role",
    "api.team.role.update": "api\/team\/role\/{role}",
    "api.team.role.set-default": "api\/team\/role\/{role}\/default",
    "api.team.role.destroy": "api\/team\/role\/{role}",
    "api.team.group.index": "api\/team\/group",
    "api.team.group.store": "api\/team\/group",
    "api.team.group.show": "api\/team\/group\/{group}",
    "api.team.group.update": "api\/team\/group\/{group}",
    "api.team.group.destroy": "api\/team\/group\/{group}",
    "api.team.invoice.index": "api\/team\/invoice",
    "api.team.invoice.download": "api\/team\/invoice\/{id}\/download",
    "api.team.payment_method.update": "api\/team\/payment-method",
    "api.team.subscription.store": "api\/team\/subscription",
    "api.team.subscription.update": "api\/team\/subscription",
    "api.team.subscription.pause": "api\/team\/subscription\/pause",
    "api.team.subscription.resume": "api\/team\/subscription\/resume",
    "api.team.subscription.cancel": "api\/team\/subscription\/cancel",
    "api.team.subscription.storage.store": "api\/team\/subscription\/storage",
    "api.team.subscription.storage.update": "api\/team\/subscription\/storage",
    "api.team.subscription.transcribe.store": "api\/team\/subscription\/transcribe",
    "api.team.subscription.transcribe.update": "api\/team\/subscription\/transcribe",
    "api.team.subscription.custom_domain.store": "api\/team\/subscription\/custom_domain",
    "api.team.subscription.automation.store": "api\/team\/subscription\/automation",
    "api.team.subscription.contact.store": "api\/team\/subscription\/contact",
    "api.team.subscription.contact.update": "api\/team\/subscription\/contact",
    "api.team.setting.patch": "api\/team\/setting",
    "api.team.photo.update": "api\/team\/photo",
    "api.team.icon.update": "api\/team\/icon",
    "api.team.profile.set": "api\/team\/profile",
    "api.team.address.set": "api\/team\/address",
    "api.team.invitation.resend": "api\/team\/invitation\/{invitation}\/resend",
    "api.team.invitation.index": "api\/team\/invitation",
    "api.team.invitation.store": "api\/team\/invitation",
    "api.team.invitation.destroy": "api\/team\/invitation\/{invitation}",
    "api.team.invitation.upload": "api\/team\/invitation\/upload",
    "api.team.integration.index": "api\/team\/integration",
    "api.team.integration.store": "api\/team\/integration\/{type}",
    "api.team.integration.failure-logs": "api\/team\/integration\/{type}\/failure-logs",
    "api.team.integration.update-options": "api\/team\/integration\/{type}\/options",
    "api.team.integration.destroy": "api\/team\/integration\/{type}",
    "api.team.configuration_set.index": "api\/team\/configuration_set",
    "api.team.configuration_set.store": "api\/team\/configuration_set",
    "api.team.configuration_set.show": "api\/team\/configuration_set\/{set}",
    "api.team.configuration_set.update": "api\/team\/configuration_set\/{set}",
    "api.team.configuration_set.destroy": "api\/team\/configuration_set\/{set}",
    "api.team.broadcast_setting.index": "api\/broadcast_setting",
    "api.team.broadcast_setting.store": "api\/broadcast_setting",
    "api.team.broadcast_setting.show": "api\/broadcast_setting\/{setting}",
    "api.team.broadcast_setting.update": "api\/broadcast_setting\/{setting}",
    "api.team.broadcast_setting.destroy": "api\/broadcast_setting\/{setting}",
    "api.team.broadcast_setting.enable": "api\/broadcast_setting\/{setting}\/enabled",
    "api.team.sms-conversation.index": "api\/team\/broadcast-setting\/{setting}\/sms-conversation",
    "api.team.sms-conversation.find": "api\/team\/broadcast-setting\/{setting}\/sms-conversation\/contact\/{contact}\/find",
    "api.team.sms-conversation.send": "api\/team\/broadcast-setting\/{setting}\/sms-conversation\/contact\/{contact}",
    "api.team.sms-conversation.messages": "api\/team\/broadcast-setting\/{setting}\/sms-conversation\/{conversation}\/messages",
    "api.landing-page.index": "api\/landing-page",
    "api.landing-page.store": "api\/landing-page",
    "api.landing-page.show": "api\/landing-page\/{landing_page}",
    "api.landing-page.update": "api\/landing-page\/{landing_page}",
    "api.landing-page.destroy": "api\/landing-page\/{landing_page}",
    "api.landing-page.detail.update": "api\/landing-page\/{landing_page}\/detail",
    "api.landing-page.clone": "api\/landing-page\/{landing_page}\/clone",
    "api.campaign.recipient_count": "api\/campaign\/recipient_count",
    "api.campaign.test_mail": "api\/campaign\/test_mail",
    "api.campaign.test_sms": "api\/campaign\/test_sms",
    "api.campaign.contacts": "api\/campaign\/{campaign}\/contacts",
    "api.campaign.cancel": "api\/campaign\/{campaign}\/cancel",
    "api.campaign.retry": "api\/campaign\/{campaign}\/retry",
    "api.campaign.update-title": "api\/campaign\/{campaign}\/title",
    "api.campaign.bulk-action.destroy": "api\/campaign\/bulk-action\/destroy",
    "api.campaign.index": "api\/campaign",
    "api.campaign.store": "api\/campaign",
    "api.campaign.show": "api\/campaign\/{campaign}",
    "api.campaign.update": "api\/campaign\/{campaign}",
    "api.campaign.destroy": "api\/campaign\/{campaign}",
    "api.workflow.contact.index": "api\/workflow\/{workflow}\/contact",
    "api.workflow.contact.stop": "api\/workflow\/{workflow}\/contact\/{contact_workflow}\/stop",
    "api.workflow.contact.resume": "api\/workflow\/{workflow}\/contact\/{contact_workflow}\/resume",
    "api.workflow.node.store": "api\/workflow\/{workflow}\/node",
    "api.workflow.node.update_position": "api\/workflow\/{workflow}\/node\/position",
    "api.workflow.node.update": "api\/workflow\/{workflow}\/node\/{node}",
    "api.workflow.node.destroy": "api\/workflow\/{workflow}\/node\/{node}",
    "api.workflow.bulk-action.destroy": "api\/workflow\/bulk-action\/destroy",
    "api.workflow.index": "api\/workflow",
    "api.workflow.store": "api\/workflow",
    "api.workflow.show": "api\/workflow\/{workflow}",
    "api.workflow.destroy": "api\/workflow\/{workflow}",
    "api.workflow.update": "api\/workflow\/{workflow}",
    "api.workflow.patch": "api\/workflow\/{workflow}",
    "api.workflow.replicate": "api\/workflow\/{workflow}\/replicate",
    "api.email-template.index": "api\/email-template",
    "api.email-template.store": "api\/email-template",
    "api.email-template.show": "api\/email-template\/{email_template}",
    "api.email-template.destroy": "api\/email-template\/{email_template}",
    "api.email-template.patch": "api\/email-template\/{email_template}",
    "api.email-template.clone": "api\/email-template\/{email_template}\/clone",
    "api.video_template.index": "api\/video_template",
    "api.video_template.store": "api\/video_template",
    "api.video_template.show": "api\/video_template\/{video_template}",
    "api.video_template.destroy": "api\/video_template\/{video_template}",
    "api.video_template.patch": "api\/video_template\/{video_template}",
    "api.video_template.clone": "api\/video_template\/{video_template}\/clone",
    "api.crm_stage.index": "api\/crm_stage",
    "api.crm_stage.store": "api\/crm_stage",
    "api.crm_stage.update": "api\/crm_stage\/{crm_stage}",
    "api.crm_stage.destroy": "api\/crm_stage\/{crm_stage}",
    "api.crm_stage.move": "api\/crm_stage\/{crm_stage}\/move",
    "api.crm-deal-pipeline.index": "api\/crm-deal-pipeline",
    "api.crm-deal-pipeline.store": "api\/crm-deal-pipeline",
    "api.crm-deal-pipeline.update": "api\/crm-deal-pipeline\/{crm_deal_pipeline}",
    "api.crm-deal-pipeline.destroy": "api\/crm-deal-pipeline\/{crm_deal_pipeline}",
    "api.crm_deal.index": "api\/crm_deal",
    "api.crm_deal.store": "api\/crm_deal",
    "api.crm_deal.show": "api\/crm_deal\/{crm_deal}",
    "api.crm_deal.update": "api\/crm_deal\/{crm_deal}",
    "api.crm_deal.destroy": "api\/crm_deal\/{crm_deal}",
    "api.crm_deal.move": "api\/crm_deal\/{crm_deal}\/move",
    "api.crm_custom_property.index": "api\/crm_custom_property",
    "api.crm_custom_property.store": "api\/crm_custom_property",
    "api.crm_custom_property.update": "api\/crm_custom_property\/{crm_custom_property}",
    "api.crm_custom_property.destroy": "api\/crm_custom_property\/{crm_custom_property}",
    "api.crm_custom_property.move": "api\/crm_custom_property\/{crm_custom_property}\/move",
    "api.crm_custom_property_folder.index": "api\/crm_custom_property_folder",
    "api.crm_custom_property_folder.store": "api\/crm_custom_property_folder",
    "api.crm_custom_property_folder.update": "api\/crm_custom_property_folder\/{crm_custom_property_folder}",
    "api.crm_custom_property_folder.destroy": "api\/crm_custom_property_folder\/{crm_custom_property_folder}",
    "api.crm_custom_property_folder.move": "api\/crm_custom_property_folder\/{crm_custom_property_folder}\/move",
    "api.saved_filter.index": "api\/saved_filter",
    "api.saved_filter.store": "api\/saved_filter",
    "api.saved_filter.update": "api\/saved_filter\/{saved_filter}",
    "api.saved_filter.destroy": "api\/saved_filter\/{saved_filter}",
    "api.video_preset.create_from_video": "api\/video_preset\/from_video\/{video}",
    "api.video_preset.index": "api\/video_preset",
    "api.video_preset.store": "api\/video_preset",
    "api.video_preset.show": "api\/video_preset\/{video_preset}",
    "api.video_preset.update": "api\/video_preset\/{video_preset}",
    "api.video_preset.destroy": "api\/video_preset\/{video_preset}",
    "api.youtube_credential.index": "api\/youtube_credential",
    "api.youtube_credential.update": "api\/youtube_credential\/{youtube_credential}",
    "api.youtube_credential.destroy": "api\/youtube_credential\/{youtube_credential}",
    "api.background_job.index": "api\/background_job",
    "api.background_job.show": "api\/background_job\/{background_job}",
    "api.tag.index": "api\/tag",
    "api.tag.store": "api\/tag",
    "api.tag.update": "api\/tag\/{tag}",
    "api.tag.patch": "api\/tag\/{tag}",
    "api.tag.destroy": "api\/tag\/{tag}",
    "api.tag.bulk-action.destroy": "api\/tag\/bulk-action\/destroy",
    "api.password.update": "api\/settings\/password",
    "api.settings.security.2fa.request": "api\/settings\/2fa\/request",
    "api.settings.security.2fa.enable": "api\/settings\/2fa\/enable",
    "api.settings.security.2fa.disable": "api\/settings\/2fa\/disable",
    "api.track.index": "api\/track",
    "api.track.destroy": "api\/track\/{track}",
    "api.call_to_action.index": "api\/call_to_action",
    "api.call_to_action.store": "api\/call_to_action",
    "api.call_to_action.show": "api\/call_to_action\/{call_to_action}",
    "api.call_to_action.update": "api\/call_to_action\/{call_to_action}",
    "api.call_to_action.destroy": "api\/call_to_action\/{call_to_action}",
    "api.call_to_action.clone": "api\/call_to_action\/{call_to_action}\/clone",
    "api.coupon.check.team": "api\/coupon\/check\/team",
    "api.redeem_partner_code": "api\/redeem-partner-code",
    "api.video.video_reply.index": "api\/video\/{video}\/video_reply",
    "api.video.video_reply.show": "api\/video\/{video}\/video_reply\/{reply}",
    "api.video_reply.index": "api\/video_reply",
    "api.video_reply.convert_to_video": "api\/video_reply\/{reply}\/convert_to_video",
    "api.video_reply.destroy": "api\/video_reply\/{reply}",
    "api.integration.destroy": "api\/integration\/{type}",
    "api.text-to-speech.synthesize": "api\/text-to-speech\/synthesize",
    "api.transcript.store": "api\/transcript",
    "api.transcript.show": "api\/transcript\/{id}",
    "api.plan.index": "api\/plan",
    "api.invitation.show": "api\/invitations\/{token}",
    "api.chrome_extension.version": "api\/chrome-extension\/version",
    "api.video.video_reply.store": "api\/video\/{video}\/video_reply",
    "api.upload.video_reply_preview": "api\/upload\/video_reply\/preview",
    "api.upload.multipart": "api\/upload\/multipart",
    "api.upload.sign": "api\/upload\/sign",
    "api.upload.multipart.create": "api\/upload\/multipart\/create",
    "api.upload.multipart.presign": "api\/upload\/multipart\/{upload_id}\/presign",
    "api.upload.multipart.complete": "api\/upload\/multipart\/{upload_id}\/complete",
    "api.upload.multipart.abort": "api\/upload\/multipart\/{upload_id}\/abort",
    "api.form.submission.store": "api\/form\/{form}\/submission",
    "webhook.lead.google_ad": "webhook\/lead\/google_ad",
    "webhook.stripe": "webhook\/stripe",
    "webhook.assemblyai.transcribe": "webhook\/assemblyai\/transcribe",
    "webhook.twilio.sms": "webhook\/twilio\/sms",
    "webhook.sendgrid-inbound.dubb-sender": "webhook\/sendgrid-inbound\/dubb-sender",
    "webhook.replicate.avatar-video": "webhook\/replicate\/avatar-video",
    "webhook.crm.active-campaign": "webhook\/crm\/active-campaign\/{hash_team_id}",
    "webhook.crm.aweber": "webhook\/crm\/aweber\/{hash_team_id}",
    "webhook.crm.chime": "webhook\/crm\/chime\/{team_id}",
    "webhook.crm.lofty": "webhook\/crm\/lofty\/{team_id}",
    "webhook.crm.close": "webhook\/crm\/close\/{team_id}",
    "webhook.crm.facebook-page": "webhook\/crm\/facebook-page",
    "webhook.crm.follow-up-boss": "webhook\/crm\/follow-up-boss\/{team_id}",
    "webhook.crm.high-level": "webhook\/crm\/high-level",
    "webhook.crm.hubspot": "webhook\/crm\/hubspot",
    "webhook.crm.realvolve": "webhook\/crm\/realvolve\/{hash_team_id}",
    "webhook.crm.salesforce": "webhook\/crm\/salesforce",
    "webhook.crm.sierra-interactive": "webhook\/crm\/sierra-interactive\/{team_id}",
    "webhook.crm.wise-agent": "webhook\/crm\/wise-agent\/{team_id}",
    "webhook.crm.woodpecker": "webhook\/crm\/woodpecker\/{team_id}",
    "octane.state": "octane\/state",
    "octane.kill-worker": "octane\/kill-worker",
    "octane.gc": "octane\/gc",
    "doc.api.html": "doc\/api.html",
    "doc.api.json": "doc\/api.json"
};