import axios from 'axios'

export default {
  /**
     * Helper method for making POST HTTP requests.
     */
  post (uri, form) {
    return Spark.sendForm('post', uri, form)
  },

  /**
     * Helper method for making PUT HTTP requests.
     */
  put (uri, form) {
    return Spark.sendForm('put', uri, form)
  },

  /**
     * Helper method for making PATCH HTTP requests.
     */
  patch (uri, form) {
    return Spark.sendForm('patch', uri, form)
  },

  /**
     * Helper method for making DELETE HTTP requests.
     */
  delete (uri, form) {
    return Spark.sendForm('delete', uri, form)
  },

  async reject (error) {
    if (!error) {
      await Promise.reject(new Error('Unknown Error'))
    } else if (error instanceof Error) {
      await Promise.reject(error)
    }
  },

  /**
     * Send the form to the back-end server.
     *
     * This function will clear old errors, update "busy" status, etc.
     */
  sendForm (method, uri, form) {
    return new Promise((resolve, reject) => {
      form.startProcessing()

      axios[method](uri, form.getPayload())
        .then(response => {
          form.finishProcessing()

          resolve(response.data)
        })
        .catch(errors => {
          form.setErrors(errors.response.data)
          form.setRawError(errors)

          reject(errors.response.data)
        })
    })
  }
}
