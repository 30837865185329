import Store from 'store'
import StorageKeys from './storage-keys'

export default class BrowserNotificationManager {
  static hasNotificationPermission = false

  static init () {
    if (typeof Notification === 'undefined' || !Notification.requestPermission || Notification.permission === 'denied' || Store.get(StorageKeys.STOP_REQUEST_NOTIFICATION_PERMISSION)) {
      return
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return
    }

    if (Notification.permission === 'granted') {
      this.hasNotificationPermission = true
      return
    }

    window.SwalConfirm.fire({
      title: `<img src='${window.constants.GLOBAL.DUBB_ICON_URL}' width='80px'>`,
      html: '<span style=\'font-size: 20px\'>Turn on notifications to get real-time activity reporting on the videos you share.</span>',
      cancelButtonText: 'No Thanks'
    }).then((res) => {
      if (res.value) {
        Notification.requestPermission((status) => {
          if (status !== 'granted') {
            Store.set(StorageKeys.STOP_REQUEST_NOTIFICATION_PERMISSION, 1)
            return
          }
          /* eslint-disable no-new */
          new Notification('Here\'s an example of a Notification from Dubb.', {
            icon: window.constants.GLOBAL.DUBB_ICON_URL,
            body: 'To manage your notifications, visit Settings > Notifications. There you can specify which notifications you receive. '
          })
          this.hasNotificationPermission = true
        })
      } else {
        Store.set(StorageKeys.STOP_REQUEST_NOTIFICATION_PERMISSION, 1)
      }
    })
  }
}
