/**
 * Created by leo108 on 2017/5/23.
 */

import URI from 'urijs'
import Store from 'store'
import StorageKeys from '../common/storage-keys';

(function () {
  let query = URI.parseQuery((new URI(location.href)).search())
  let force = false
  if (window.forceUTM) {
    query = window.forceUTM
    force = true
  }
  const source = query.utm_source
  const medium = query.utm_medium
  const campaign = query.utm_campaign
  const content = query.utm_content
  const term = query.utm_term
  if (source && (!Store.get(StorageKeys.UTM.SOURCE) || force)) {
    Store.set(StorageKeys.UTM.SOURCE, source)
  }
  if (medium && (!Store.get(StorageKeys.UTM.MEDIUM) || force)) {
    Store.set(StorageKeys.UTM.MEDIUM, medium)
  }
  if (campaign && (!Store.get(StorageKeys.UTM.CAMPAIGN) || force)) {
    Store.set(StorageKeys.UTM.CAMPAIGN, campaign)
  }
  if (content && (!Store.get(StorageKeys.UTM.CONTENT) || force)) {
    Store.set(StorageKeys.UTM.CONTENT, content)
  }
  if (term && (!Store.get(StorageKeys.UTM.TERM) || force)) {
    Store.set(StorageKeys.UTM.TERM, term)
  }

  if (!Spark.state.user) {
    if (!Store.get(StorageKeys.REFERRER_URL)) {
      Store.set(StorageKeys.REFERRER_URL, document.referrer)
    }
    if (!Store.get(StorageKeys.LANDING_URL)) {
      Store.set(StorageKeys.LANDING_URL, location.href)
    }
    if (!Store.get('team_software') && query.int) {
      Store.set('team_software', query.int)
    }
  }
})()
