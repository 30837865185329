import Vue from 'vue'
import { DateTime } from 'luxon'

import { number_with_commas } from '../../utils'

/**
 * Format the given date.
 */
Vue.filter('date', (value, zone = null) => {
  if (!value) {
    return ''
  }

  return DateTime
    .fromFilterValue(value)
    .setZone(zone || Spark.getTimezone())
    .toCustomLocaleString(DateTime.CUSTOM_DATE_FULL)
})

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', (value, zone = null) => {
  if (!value) {
    return ''
  }

  return DateTime
    .fromFilterValue(value)
    .setZone(zone || Spark.getTimezone())
    .toCustomLocaleString(DateTime.CUSTOM_DATETIME_MED)
})

/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
Vue.filter('capitalize', value => {
  if (!value && value !== 0) {
    return ''
  }

  return value.toString().charAt(0).toUpperCase() +
    value.slice(1)
})

Vue.filter('currency', (value, digits = 2) => {
  value = parseFloat(value)

  if (!isFinite(value) || (!value && value !== 0)) {
    return ''
  }

  const sign = value < 0 ? '-' : ''

  return sign + '$' + number_with_commas(Math.abs(value))
})

Vue.filter('number_with_commas', value => {
  return number_with_commas(value)
})
