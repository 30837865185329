/**
 * Created by leo108 on 2019/10/20.
 */
import { DateTime, SystemZone } from 'luxon'
import URI from 'urijs'
import BackendRouterGenerator from '../common/backend-router-generator'
import { is_unlimited_quota } from '../utils'

Spark.router = BackendRouterGenerator(require('./../generated/route'))
Spark.hasTeam = () => {
  return !!Spark.state.currentTeam
}
Spark.ownsTeam = () => {
  if (!Spark.hasTeam()) {
    return false
  }

  return Spark.state.currentTeam.owner_id === Spark.state.user.id
}
Spark.teamHasFeature = feature => {
  if (!Spark.hasTeam()) {
    return false
  }

  const team = Spark.state.currentTeam
  return team.features && team.features[feature] ? team.features[feature] : false
}
Spark.teamGetQuota = (quotaName, unlimitedAsString = false) => {
  if (!Spark.hasTeam()) {
    return false
  }

  const team = Spark.state.currentTeam
  const quota = team.quotas && team.quotas[quotaName] ? team.quotas[quotaName] : 0

  if (unlimitedAsString && is_unlimited_quota(quota)) {
    return 'Unlimited'
  }

  return quota
}
Spark.teamHasQuota = quotaName => {
  const quota = Spark.teamGetQuota(quotaName)
  return is_unlimited_quota(quota) || quota > 0
}

Spark.roleHasPermission = permission => {
  if (!Spark.hasTeam()) {
    return true
  }
  return Spark.ownsTeam() || (Spark.state.user.team_role !== null && Spark.state.user.team_role.permissions.indexOf(permission) >= 0)
}
Spark.teamHasActiveCoreSubscription = () => {
  if (!Spark.hasTeam()) {
    return false
  }

  const subscription = Spark.state.currentTeam.default_subscription

  return subscription !== null && (subscription.active || subscription.active_in_stripe)
}
Spark.teamOnTrial = () => {
  if (!Spark.hasTeam()) {
    return false
  }

  return Spark.state.currentTeam.on_generic_trial
}
Spark.getTeamVideoDomain = () => {
  const currentTeam = Spark.state.currentTeam

  if (currentTeam && currentTeam.features[window.constants.GLOBAL.FEATURE_CUSTOM_DOMAIN]) {
    const domains = currentTeam.domains.filter(domain => domain.type === window.constants.App_Models_TeamDomain.TYPE_VIDEO && domain.is_valid)

    if (domains.length > 0) {
      return domains[0].domain
    }
  }

  return (new URI(location.href)).hostname()
}
Spark.getTimezone = () => {
  return Spark.state.user && Spark.state.user.timezone ? Spark.state.user.timezone : SystemZone.instance.name
}

Spark.getDisk = (user = null) => {
  if (user === null) {
    user = Spark.state.user
  }

  if (user !== null && typeof user.extra === 'object' && user.extra !== null && typeof user.extra.disk === 'string' && typeof window.maps.GLOBAL.DISKS[user.extra.disk] !== 'undefined') {
    return user.extra.disk
  }

  return window.constants.GLOBAL.DEFAULT_DISK
}

Spark.getSubscriptionQuantity = plan => {
  if (!Spark.state.currentTeam) {
    return 0
  }

  const subscriptions = Spark.state.currentTeam.subscriptions.filter(subscription => subscription.active && subscription.stripe_price === plan)

  if (subscriptions.length === 0) {
    return 0
  }

  return subscriptions[0].quantity
}

Spark.getTrialLeftDays = () => {
  if (!Spark.hasTeam()) {
    return null
  }
  if (Spark.teamHasActiveCoreSubscription()) {
    return null
  }
  if (Spark.state.currentTeam.trial_ends_at === null) {
    return 0
  }

  const endsAt = DateTime.fromISO(Spark.state.currentTeam.trial_ends_at, { zone: 'UTC' })
  const diff = endsAt.diff(DateTime.now(), 'hours')

  return diff < 0 ? 0 : Math.ceil(diff.hours / 24)
}

Spark.teamHasSpecialSet = specialSet => Spark.hasTeam() && Spark.state.currentTeam.extra.special_sets.indexOf(specialSet) >= 0

Spark.isLockedByConfigurationSet = key => {
  if (!Spark.hasTeam() || !Spark.state.user?.team_role?.team_configuration_set) {
    return false
  }

  return Spark.state.user.team_role.team_configuration_set.locks[key]
}

Spark.getConfigurationSetLockValue = key => {
  if (!Spark.hasTeam() || !Spark.state.user?.team_role?.team_configuration_set) {
    return null
  }

  return Spark.state.user.team_role.team_configuration_set.values[key]
}

Spark.userHasAffiliatePartner = () => {
  return Spark.state.user.affiliate_partner.vendor !== null && Spark.state.user.affiliate_partner.partner_key !== null
}
