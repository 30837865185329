import Store from 'store'

Store.addPlugin(function () {
  return {
    getWithCallback (super_fn, key, onSuccess = null, onFailure = null) {
      const ret = this.get(key)

      if (typeof ret === 'undefined') {
        if (onFailure !== null) {
          onFailure()
        }
      } else {
        if (onSuccess !== null) {
          onSuccess(ret)
        }
      }

      return ret
    }
  }
})
