window.SparkForm = function (data) {
  const form = this
  const initialData = JSON.parse(JSON.stringify(data))

  Object.assign(this, data)

  this.errors = new window.SparkFormErrors()
  this.rawError = null
  this.busy = false
  this.successful = false

  this.startProcessing = function () {
    form.errors.forget()
    form.busy = true
    form.successful = false
    form.rawError = null
  }

  this.finishProcessing = function () {
    form.busy = false
    form.successful = true
  }

  this.resetStatus = function () {
    form.errors.forget()
    form.busy = false
    form.successful = false
    form.rawError = null
  }

  this.setErrors = function (errors) {
    form.busy = false
    form.errors.set(errors)
  }

  this.setRawError = function (rawError) {
    form.rawError = rawError
  }

  this.reset = () => {
    Object.assign(form, initialData)
    this.resetStatus()
  }

  this.getPayload = () => {
    const data = Object.assign({}, form)
    delete data.errors
    delete data.rawError
    delete data.busy
    delete data.successful

    return data
  }

  this.initialData = () => initialData

  this.loadData = data => {
    Object.keys(initialData).forEach(key => {
      if (typeof data[key] !== 'undefined') {
        form[key] = data[key]
      }
    })
    this.resetStatus()
  }
}
