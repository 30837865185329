/**
 * Created by leo108 on 2019/10/20.
 */
import Vue from 'vue'

Vue.mixin({
  data () {
    return {
      maps: window.maps,
      constants: window.constants
    }
  },
  computed: {
    hasTeam () {
      return Spark.hasTeam()
    },
    spark () {
      return window.Spark
    }
  },
  methods: {
    router: Spark.router,
    ownsTeam: Spark.ownsTeam,
    teamHasFeature: Spark.teamHasFeature,
    roleHasPermission: Spark.roleHasPermission,
    teamGetQuota: Spark.teamGetQuota,
    teamHasQuota: Spark.teamHasQuota,
    teamHasActiveCoreSubscription: Spark.teamHasActiveCoreSubscription,
    teamOnTrial: Spark.teamOnTrial,
    getTimezone: Spark.getTimezone,
    openTab (url) {
      window.open(url)
    }
  }
})
