<template>
  <span
    :aria-label="exactTime"
    :class="hintClassName"
  >
    {{ relativeTime }}
  </span>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'RelativeTime',
  props: {
    time: {
      required: true
    },
    locale: {
      type: String
    },
    placement: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    hintClassName () {
      return `hint--${this.placement}`
    },
    relativeTime () {
      if (!this.time) {
        return ''
      }

      const datetime = DateTime.fromFilterValue(this.time).toLocal()

      if (this.locale) {
        datetime.setLocale(this.locale)
      }

      return datetime.toRelative()
    },
    exactTime () {
      if (!this.time) {
        return ''
      }

      return DateTime
        .fromFilterValue(this.time)
        .setZone(this.spark.getTimezone())
        .toCustomLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    }
  }
}
</script>
